import { Helmet } from "react-helmet";
import { motion } from "framer-motion";

const SellerOverview = () => {
  const metaTitle = "Seller Overview | Mary Nathan";
  const metaDescription =
    "Maximize your selling potential with our comprehensive seller overview page. Gain valuable insights into the selling process, market trends, and effective strategies to achieve optimal results.";
  const metaImage =
    "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

  document.title = metaTitle;

  document
    .querySelector('meta[property="og:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[property="og:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[property="og:image"]')
    .setAttribute("content", metaImage);
  document
    .querySelector('meta[property="og:url"]')
    .setAttribute("content", window.location.href);

  document
    .querySelector('meta[name="twitter:title"]')
    .setAttribute("content", metaTitle);
  document
    .querySelector('meta[name="twitter:description"]')
    .setAttribute("content", metaDescription);
  document
    .querySelector('meta[name="twitter:image"]')
    .setAttribute("content", metaImage);

  return (
    <section className="seller-overview">
      <Helmet>
        <title>
          Seller Overview - Mary Nathan | Amberwood Real Estate Inc.
        </title>
      </Helmet>

      {/* PAGE BANNER */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-banner"
      >
        <div className="overlay-text">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-10 col-sm-12">
                <h1>SELLER OVERVIEW</h1>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* PAGE CONTENT */}
      <motion.div
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1 },
          hidden: { opacity: 0 },
        }}
        className="page-content"
      >
        <div className="content">
          <div className="container">
            <p>
              If you’re planning on selling your home, then you might be
              wondering whether you should just do it yourself. After all, how
              hard can it be to list your house online? Odds are you think quite
              highly of your home, which means you expect to sell it quickly.
              What’s the point of paying a listing agent to do something you can
              do? The thing is, a listing agent is much more valuable than you
              might think, and not working with one can be detrimental to the
              process of selling your house.
            </p>
          </div>
        </div>

        <div className="content with-image">
          <div className="container">
            <div className="row align-items-center gx-5">
              <div className="col-xl-7 col-sm-12">
                <h2>WHAT IS A LISTING AGENT?</h2>
                <p>
                  A listing agent is a real estate agent that represents
                  homeowners who are trying to sell their homes. When you hire a
                  listing agent, they will do the following:
                </p>
                <ul>
                  <li>
                    <b>Prep your home.</b> They will recommend repairs or
                    improvements as well as offer advice on how to stage your
                    home to attract the most buyers. This process will ensure
                    that it sells quickly and at the highest possible price.
                  </li>
                  <li>
                    <b>Price your home.</b> Using recent local market data, they
                    will accurately price your home to promote buyer interest.
                  </li>
                  <li>
                    <b>List your home.</b> They will create a detailed
                    description coupled with inviting photos to showcase your
                    home.
                  </li>
                  <li>
                    <b>Market your home.</b> They will advertise your home in
                    order to attract motivated buyers.
                  </li>
                  <li>
                    <b>Show your home.</b> They will hold open houses as well as
                    personal showings to attract buyers.
                  </li>
                  <li>
                    <b>Negotiate deals.</b> They will negotiate with the buyer’s
                    agents on your behalf.
                  </li>
                  <li>
                    <b>Closing.</b> They will guide you through the closing
                    process.
                  </li>
                </ul>
              </div>
              <div className="col-xl-5 col-sm-12">
                <img
                  src={require("../../assets/what-is-listing-agent.webp")}
                  alt="What is a listing agent"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="content margin">
          <div className="container">
            <h2>DIFFERENCE BETWEEN A LISTING AGENT AND A BUYER’S AGENT</h2>
            <p>
              A listing agent is a real estate agent that represents the
              homeowner who is selling the house. They help assist and guide the
              homeowner throughout the selling process, from helping prepare the
              property for the market and pricing it properly to listing it,
              advertising it, and finally, selling it. The listing agent
              represents the seller and the buyer’s agent represents the buyer.
            </p>
          </div>
        </div>

        <div className="content margin">
          <div className="container">
            <h2>FIND A LISTING AGENT YOU CAN TRUST</h2>
            <p>
              Once you’ve decided to sell your home, you’ll want to find a
              listing agent to help you through what is a complicated and often
              stressful process. The thing is, there are a lot of listing agents
              out there, which means choosing the right one may seem like a
              daunting task. Not all listing agents provide the same quality
              service, after all. The following are a few tips to help you find
              a listing agent that you can trust:
            </p>
            <ul>
              <li>
                <b>Consider how they are paid.</b> Be wary of listing agents
                that charge an upfront fee. They are less likely to do the
                required legwork if they’ve already been paid. Most reputable
                agents work on commission.
              </li>
              <li>
                <b>Look for an experienced agent.</b> Not only do you want to
                work with a listing agent that has experience selling houses,
                but you want to make sure that they have experience in the area
                where you live. It means that they understand the local market
                and have an established network of resources at their
                fingertips.
              </li>
              <li>
                <b>Look for a full-time agent.</b> Part-time agents may not put
                as much work into selling your home because they don’t have as
                much time to dedicate to you.
              </li>
              <li>
                <b>Ask about their marketing plan.</b> A good listing agent will
                have a thorough marketing plan that includes advice to stage the
                house, high-quality photographs, a detailed listing on multiple
                websites, advertising online and offline, setting up open
                houses, and more.
              </li>
              <li>
                <b>Ask how they determine the pricing.</b> A good agent will
                compare your property with similar properties that have sold
                recently within the same area. They will also provide you with a
                range instead of an exact number, and together you can determine
                a good asking price to promote buyer interest.
              </li>
              <li>
                <b>Consider how engaged they are.</b> When speaking with the
                listing agent, pay attention to how they communicate with you.
                Are they actually listening? Are they asking lots of questions?
                Are they friendly? Do you get along with them? All this is
                important since you’ll want to have a relationship that’s built
                on trust and transparency.
              </li>
              <li>
                <b>Ask about certifications.</b> Certain certifications and
                memberships indicate the quality of the listing agent. The more
                certifications they have, the better.
              </li>
              <li>
                <b>Request referrals.</b> A reputable agent should be able to
                refer past clients to speak on behalf of their quality.
              </li>
            </ul>
          </div>
        </div>

        <div className="content margin">
          <div className="container">
            <h2>FIND YOUR LISTING AGENT AT AMBERWOOD REAL ESTATE</h2>
            <p>
              Here at Amberwood Real Estate, we provide our clients with expert
              knowledge, valuable experience, a well-defined marketing plan, and
              a cohesive network to help sell your home quickly. To schedule a
              personal consultation, contact us today to speak with one of our
              listing agents.
            </p>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default SellerOverview;

import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";

/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.scss";

/* LAYOUT IMPORTS */
import Layout from '../layout/index';

/* PAGES IMPORT */
import Home from '../pages/index';
import ActiveListings from "../pages/listings";
import FeaturedListings from "../pages/listings/featured-listing";
import PropertyDetails from "../pages/listings/detail-view";
import ContactUs from "../pages/contact";
import BuyerOverview from "../pages/buyer-overview";
import SellerOverview from "../pages/seller-overview";
import Blogs from "../pages/blogs";
import BlogDetail from "../pages/blogs/blog-detail";
import HomeEvaluation from "../pages/home-evaluation/index";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const WebsiteRoutes = () => {
  return (
    <ThemeProvider breakpoints={["xl", "lg", "md", "sm"]} minBreakpoint="sm">
      <div className="app">
        <Router>
          <ScrollToTop />
          <Layout>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/find-a-home' element={<ActiveListings />} />
              <Route path='/featured-listings' element={<FeaturedListings />} />
              <Route path='/property-detail/:mlsId/:mlsKey' element={<PropertyDetails />} />
              <Route path='/contact-me' element={<ContactUs />} />
              <Route path='/buying' element={<BuyerOverview />} />
              <Route path='/selling' element={<SellerOverview />} />
              <Route path='/blogs' element={<Blogs />} /> 
              <Route path="/blog-detail/:id" element={<BlogDetail />} />
              <Route path="/home-evaluation" element={<HomeEvaluation />} />
            </Routes>
          </Layout>
        </Router>
      </div>
    </ThemeProvider>
  );
};

export default WebsiteRoutes;

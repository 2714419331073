/* eslint-disable no-useless-concat */
import { Card } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation, Pagination } from "swiper";
import { motion } from 'framer-motion';
import { Link } from "react-router-dom";

/* IMAGE IMPORTS */
import AmberwoodPlaceholder from '../assets/amberwood-placeholder-img.webp';

/* ICON IMPORTS */
import BedIcon from "../assets/icons/bed-icon.png";
import BathIcon from "../assets/icons/bath-icon.png";
import CameraIcon from "@iconscout/react-unicons/icons/uil-camera";

const ListingListView = (props) => {
    console.warn(props);

     const formatter = new Intl.NumberFormat("en-US", {
       style: "currency",
       currency: "USD",
       maximumFractionDigits: 0,
     });

     const unitFormatter = new Intl.NumberFormat("en-US", {
       notation: "standard",
       maximumFractionDigits: 0,
     });

    return (
      <Link
        to={`/property-detail/${props?.mlsNumber}/${props?.mlsKey}`}
        style={{ textDecoration: "none", color: "#000" }}
      >
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true }}
          transition={{ duration: 0.3 }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          className="card listing-list"
          layoutId={props.streetName}
        >
          <Card.Body className="listing-list-body">
            <div className="property-images">
              <Swiper
                modules={[Scrollbar, Navigation, Pagination]}
                navigation={true}
                slidesPerView={1}
                spaceBetween={30}
              >
                {props.imgList === null ? (
                  <img
                    src={AmberwoodPlaceholder}
                    className="property-img"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src = AmberwoodPlaceholder;
                    }}
                    alt={props.streetName}
                  />
                ) : (
                  props?.imgList?.map((img) => {
                    return (
                      <SwiperSlide className="img-slider" key={img?.MediaKey}>
                        <img
                          src={img?.MediaURL}
                          className="property-img"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = AmberwoodPlaceholder;
                          }}
                          alt={props.streetName}
                        />
                      </SwiperSlide>
                    );
                  })
                )}
                {}
              </Swiper>
              <div className="favorites">
                <div
                  className={`logo-badge ${
                    props.leaseIndicator === "Lease" ? "d-block" : "d-none"
                  }`}
                >
                  <p>For Rent/Lease</p>
                </div>
              </div>
            </div>
            <div className="property-details">
              <div className="top-section">
                <h2>{`${formatter.format(props.price)}`}</h2>
                <div className="d-flex align-items-center">
                  <div
                    className={`badge ${
                      props.remainingDays === null || props.remainingDays === 0
                        ? "d-none"
                        : "d-block"
                    }`}
                  >
                    <p>
                      {props.remainingDays} day
                      {props.remainingDays > 1 ? "s" : ""} on market
                    </p>
                  </div>
                  <div className="images-number">
                    <CameraIcon size="16" color="#FFF" />
                    <p>{props?.imgList?.length}</p>
                  </div>
                </div>
              </div>
              <div className="middle-section">
                <div>
                  <h2>{props.streetName}</h2>
                  <p>{props.cityName}</p>
                </div>
              </div>
              <div className="bottom-section">
                <div className="d-flex align-items-center">
                  <p>
                    Home Size: <span>{unitFormatter.format(props.area)}</span>{" "}
                    sqft
                  </p>
                </div>
                <div className="other-details">
                  <div className="details-box">
                    <img src={BedIcon} className="icon" alt="Bed Icon" />
                    <p>{props.beds ? props.beds : 0}</p>
                  </div>
                  <div className="details-box">
                    <img src={BathIcon} className="icon" alt="Bed Icon" />
                    <p>{props.baths ? props.baths : 0}</p>
                  </div>
                </div>
              </div>
            </div>
          </Card.Body>
        </motion.div>
      </Link>
    );
};

export default ListingListView;
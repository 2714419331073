/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import { motion } from "framer-motion";

/* APIS */
import { getBlogDetailsById } from "../../service/api";
import BlogSidebar from "../../components/blogs-sidebar";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const BlogDetail = () => {
    const { id } = useParams();
    const getId = id?.split("&");
    const [loading, setLoading] = useState(true);
    const [blogDetails, setBlogDetails] = useState(null);

    useEffect(() => {
      getBlogDetails();
    }, [getId[0]]);

    /* GET BLOG DETAILS */
    const getBlogDetails = () => {
      setLoading(true);
      getBlogDetailsById(getId[0])
        .then((res) => {
          if (res?.status) {
            setBlogDetails(res?.data);
          }
          setLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLoading(false);
        })
        .finally((err) => {
          setLoading(false);
        });

      return () => {
        setBlogDetails(null);
      };
    };

    useEffect(() => {
      if (blogDetails?.name !== undefined) {
        /* UPDATE META DATA */
        const metaTitle = blogDetails?.name + " | Mary Nathan";
        const metaDescription =
          "Delve into a wealth of knowledge and inspiration through our engaging real estate blogs. Our 'Blogs' page is a treasure trove of informative articles, industry insights, and expert advice.";
        const metaImage =
          "https://storage.googleapis.com/cdn-amberwoodre/images/amberwood-og-image.png";

        document.title = metaTitle;

        document
          .querySelector('meta[property="og:title"]')
          .setAttribute("content", metaTitle);
        document
          .querySelector('meta[property="og:description"]')
          .setAttribute("content", metaDescription);
        document
          .querySelector('meta[property="og:image"]')
          .setAttribute("content", metaImage);
        document
          .querySelector('meta[property="og:url"]')
          .setAttribute("content", window.location.href);

        document
          .querySelector('meta[name="twitter:title"]')
          .setAttribute("content", metaTitle);
        document
          .querySelector('meta[name="twitter:description"]')
          .setAttribute("content", metaDescription);
        document
          .querySelector('meta[name="twitter:image"]')
          .setAttribute("content", metaImage);
      }
    }, [blogDetails]);

  return (
    <motion.section
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1 },
        hidden: { opacity: 0 },
      }}
      className="blog-detail"
    >
      <div className="container">
        <div className="row">
          <div className="col-xl-9 col-sm-12">
            {blogDetails === null ? (
              <>
                <Skeleton className="heading-loader" />
                <Skeleton className="heading-sm-height-loader" count={5} />
              </>
            ) : (
              <motion.div
                initial="hidden"
                whileInView="visible"
                viewport={{ once: true }}
                transition={{ duration: 0.3 }}
                variants={{
                  visible: { opacity: 1 },
                  hidden: { opacity: 0 },
                }}
                className="blog-detail-desc"
              >
                <h1>{blogDetails?.name}</h1>
                <div className="description-inner">
                  <p>{ReactHtmlParser(blogDetails?.discription)}</p>
                </div>
              </motion.div>
            )}
          </div>

          {/* SIDEBAR */}
          <BlogSidebar />
        </div>
      </div>
    </motion.section>
  );
};

export default BlogDetail;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

/* APIS */
import { getBlogs } from "../service/api";

/* CONFIG */
import { IMAGE_URL } from "../config";

const BlogBox = (props) => {
  return (
    <Link
      to={"/blog-detail/" + props?.blogDetails?._id}
      style={{ textDecoration: "none" }}
    >
      <Card className="blog-box">
        <Card.Body className="blog-box-body">
          <img src={IMAGE_URL + props.image} alt={props.title} />
          <div className="details">
            <h5>{props.title}</h5>
          </div>
        </Card.Body>
        <Card.Footer className="blog-box-footer">
          <p className="date">
            {moment(`${new Date(props?.datePublished)}`).format(
              "DD MMMM, YYYY"
            )}
          </p>
        </Card.Footer>
      </Card>
    </Link>
  );
};

const AboutAndBlogs = () => {
  /* STATE VARIABLES */
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(9);
  const [blogsData, setBlogsData] = useState([]);

  /* GET BLOGS LISTINGS */
  const getBlogsList = () => {
    setLoading(true);
    const obj = {};
    obj.page = page;
    obj.sizePerPage = pageSize;

    getBlogs(obj)
      .then((res) => {
        if (res?.status) {
          setBlogsData(res?.data?.docs);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      })
      .finally((err) => {
        setLoading(false);
      });

    return () => {
      setBlogsData([]);
    };
  };

  /* GET BLOGS ONLOAD */
  useEffect(() => {
    getBlogsList();
  }, []);

  useEffect(() => {
    getBlogsList();
  }, [page]);

  return (
    <section className="about-and-blogs">
      <div className="bg-overlay">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-xl-4 col-sm-12">
              <img
                src={require("../assets/creola-jefferson-profile.webp")}
                alt="Creola Jefferson"
              />
            </div>
            <div className="col-xl-7 col-sm-12">
              <div className="row">
                <div className="col-xl-10 col-sm-12">
                  <div className="heading">
                    <h2>CREOLA JEFFERSON</h2>
                    <p>
                      When I think of Creola Jefferson, I think of someone who
                      is professional, enthusiastic, caring, energetic, joyful,
                      God-fearing and the type of person I would want to work
                      with when selling or buying a home!
                    </p>
                    <p>
                      Creola simply believes in service, yes the old fashion
                      kind of service where you actually spend time to listen
                      and understand the clients needs. She recognizes that each
                      client is different with unique situations. She is highly
                      devoted and works diligently to help her clients achieve
                      their real estate goals by utilizing market data, strong
                      negotiation skills and diligent communication, keeping
                      them educated and informed throughout the the entire
                      transaction. Creola's approach to real estate is both
                      practical and passionate, and she values her relationships
                      with her clients and fellow industry professionals.
                    </p>
                    <p>
                      Creola has more than 20 years experience in the housing
                      industry. Since 2003, she has worked with her husband,
                      Tim, in their Manufactured Home Dealership family business
                      and she became a Realtor in 2010.
                    </p>
                    <p>
                      In her free time, you will find Creola enjoying time with
                      her family, on adventures with her grandsons, visiting and
                      helping seniors, working in her church ministry feeding
                      the homeless and enjoying playing her piano.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            {blogsData.slice(0, 4).map((blog) => {
              return (
                <div className="col-xl-3 col-lg-6 col-sm-12" key={blog.id}>
                  <BlogBox
                    image={blog?.image}
                    title={blog?.name}
                    datePublished={blog?.startDate}
                    blogDetails={blog}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutAndBlogs;
